function toggleGridList(){

    $('.shorting_icon').on('click',function() {
        if ($(this).hasClass('grid')) {
            $('.shop_container').removeClass('list').addClass('grid');
            $(this).addClass('active').siblings().removeClass('active');
        }
        else if($(this).hasClass('list')) {
            $('.shop_container').removeClass('grid').addClass('list');
            $(this).addClass('active').siblings().removeClass('active');
        }
        $(".shop_container").append('<div class="loading_pr"><div class="mfp-preloader"></div></div>');
        setTimeout(function(){
            $('.loading_pr').remove();
            $container.isotope('layout');
        }, 800);
    });
}
export {toggleGridList}