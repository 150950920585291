//Hide Navbar Dropdown After Click On Links
function hideNavOnLinkClick(){

    const navBar = $(".header_wrap");
    const navbarLinks = navBar.find(".navbar-collapse ul li a.page-scroll");
    
    $.each(navbarLinks, function () {
      const navbarLink = $(this);
    
      navbarLink.on("click", function () {
        navBar.find(".navbar-collapse").collapse("hide");
        $("header").removeClass("active");
      });
    });
}

// Hide nav bar on page click
function hideNavOnPageClick(){

    let rclass = true;
    
    $("html").on("click", function () {
      if (rclass) {
        $(".categories_btn").addClass("collapsed");
        $(".categories_btn,.side_navbar_toggler").attr("aria-expanded", "false");
        $("#navCatContent,#navbarSidetoggle").removeClass("show");
      }
      rclass = true;
    });
    
    $(
      ".categories_btn,#navCatContent,#navbarSidetoggle .navbar-nav,.side_navbar_toggler"
    ).on("click", function () {
      rclass = false;
    });
}

// Open mob search
function openMobSearch(){
    $(".pr_search_trigger").on("click", function () {
      $(this).toggleClass("show");
      $(".product_search_form").toggleClass("show");
    });
}

export {openMobSearch, hideNavOnPageClick, hideNavOnLinkClick}