import { host } from "../config.js";

// cart
export const cartItemHTML = item => `
    <li 
        class="cart_item product_data"
        data-id="${item.id}"
    >
        <a 
            href="javascript:void(0)" 
            class="item_remove remove_from_cart"
            data-id="${item.id}"
        >
            <i class="fas fa-times"></i>
        </a>
        <a href="${host}product-single.php">
            <img src="${host}/img/products/${item.image}" alt="cart_thumb1">
            ${item.name}
        </a>
        <span class="cart_quantity"> ${item.qty} x ${item.price.toFixed(2)} <span class="price_symbole">RSD</span></span>
    </li>
`;
export const emptyCartHTML = `<h3 style='padding: 20px; text-align: center;'>Korpa je prazna</h3>`;
export const amountHTML = total => `${total.toFixed(2)}  <span class="price_symbole">RSD</span>`;
export const cartTotalHTML = total => `<strong>Ukupno:</strong> <span class="cart_price"> ${total.toFixed(2)} <span class="price_symbole">RSD</span></span>`;

// cart item on cart page
export const cartItemRowHTML = item => `
    <tr 
        class="cart_item product_data" 
        data-id="${item.id}"
    >
        <td class="product-thumbnail">
            <a href="${host}product-single.php">
                <img src="${host}/img/products/${item.image}" alt="">
            </a>
        </td>
        <td class="product-name" data-title="Proizvod">
            <a href="${host}product-single.php">
                ${item.name}
            </a>
        </td>
        <td class="product-price" data-title="Cena">
            ${item.price.toFixed(2)}
        </td>
        <td class="product-quantity" data-title="Količina">
            <div class="quantity">
                <input type="button" value="-" class="minus">
                <input type="number" name="quantity" value="${item.qty}" title="Qty" class="qty" size="4">
                <input type="button" value="+" class="plus">
            </div>
        </td>
        <td class="product-subtotal" data-title="Ukupno">${(item.qty * +item.price).toFixed(2)}</td>
        <td class="product-remove" data-title="Izbaci">
            <a 
                href="javascript:void(0)" 
                class="item_remove remove_from_cart"
                data-id="${item.id}"
            >
                <i class="ti-close"></i>
            </a>
        </td>
    </tr>
`;

export const cartTotalContainerHTML = amount => `
    <div class="heading_s1 mb-3">
        <h6> Ukupno u korpi</h6>
    </div>
    <div class="table-responsive">
        <table class="table total_table">
            <tbody>
                <tr>
                    <td class="cart_total_label">Korpa</td>
                    <td class="cart_total_amount">${amount.toFixed(2)} RSD</td>
                </tr>
                <tr>
                    <td class="cart_total_label">Isporuka</td>
                    <td class="cart_total_amount">0</td>
                </tr>
                <tr>
                    <td class="cart_total_label">Ukupno</td>
                    <td class="cart_total_amount"><strong>${amount.toFixed(2)} </strong> RSD</td>
                </tr>
            </tbody>
        </table>
    </div>
    <a 
        href="${host}checkout.php" 
        class="btn btn-fill-out ${amount > 0 ? '' : 'disabled'}"
    >
        Nastavite sa placanjem
    </a>
`;