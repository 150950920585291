import { host } from "../config.js";

export const quickviewHTML = item => `
    <div class="row product_data" data-id="${item.id}">
        <div class="col-lg-6 col-md-6 mb-4 mb-md-0">
            <div class="product-image">
                <div class="product_img_box">
                    <img id="product_img" src='${host}img/products/${item.image}' alt="${item.name}">
                </div>

            </div>
        </div>
        <div class="col-lg-6 col-md-6">
            <div class="pr_detail">
                <div class="product_description">
                    <h4 class="product_title"><a href="javascript:void(0)">${item.name}</a></h4>
                    <div class="product_price">
                        <span class="price">${item.price.toFixed(2)}</span>
                        ${item.old_price ? `<del>${item.old_price.toFixed(2)}</del>` : ''}

                        ${item.discount_persent ? 
                        `<div class="on_sale">
                            <span>${item.discount_persent}% popusta</span>
                        </div>` 
                        : ''}
                    </div>
                    <div class="rating_wrap">
                        <div class="rating">
                            <div class="product_rate" style="width:80%"></div>
                        </div>
                        <span class="rating_num">(21)</span>
                    </div>

                    <div class="pr_desc">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus blandit massa enim. Nullam id varius nunc id varius nunc.</p>
                    </div>
                    
                </div>
                <hr>
                <div class="cart_extra">
                    <div class="cart-product-quantity">
                        <div class="quantity">
                            <input type="button" value="-" class="minus">
                            <input type="number" name="quantity" value="${item.in_cart ? item.qty : 1}" title="Qty" class="qty" size="4">
                            <input type="button" value="+" class="plus">
                        </div>
                    </div>
                    <div class="cart_btn">
                        <button 
                            class="btn btn-fill-out btn-addtocart add_to_cart ${item.in_cart ? 'isInCart' : ''}"" 
                            type="button"
                        >
                            <i class="icon-basket-loaded"></i> ${item.in_cart ? 'U Korpi' : ' Dodaj U Korpu'}
                        </button>
                        <a 
                            class="add_wishlist toggle_wishlist ${item.in_wishlist ? 'isInWishlist' : ''}" 
                            href="javascript:void(0)"
                        >
                            <i class="icon-heart"></i>
                        </a>
                    </div>
                </div>
                <hr>
                <ul class="product-meta">
                    <li>Šifra proizvoda: <a href="javascript:void(0)">${item.sku}</a></li>
                    <li>Kategorija: <a href="javascript:void(0)">LED paneli</a> | <a href="javascript:void(0)">Zidne i plafonske svetiljke</a></li>
                </ul>
            </div>
        </div>
    </div>
`