import { magnificPopups } from '../../theme-functions/popups.js';
import {host} from '../config.js';
import { refreshOwlCarousel } from '../utility.js';
import { productBoxHTML, productBoxSmallHTML } from './htmlMarkups.js';

// const isHomePage = $('.front-page').length > 0;
const cart = JSON.parse(localStorage.getItem('cart')) || []
const state = {
    cart,
    wishlistIds: JSON.parse(localStorage.getItem('wishlist')) || [],
    cartIds: cart.map(cartItem => cartItem.id),
}

export async function home() {
    
    const renderShopSection1 = async () => {
        try {
            // fake api
            const data  = await $.ajax({
                url: `${host}danny_data/products.json`,
                type: 'GET'
            });
            // slider 1
            const owl1 = $('.product_slider1_section1');
            updateSlider(data, owl1);
            // slider 2
            const owl2 = $('.product_slider2_section1');
            updateSlider(data, owl2);
    
            // slider 3
            const owl3 = $('.product_slider3_section1');
            updateSlider(data, owl3);
        } catch (error) {
            console.log('error in section 1: ', error)
        }
    }
    const renderShopSection2 = async () => {
        try {
            // fake api
            const data  = await $.ajax({
                url: `${host}danny_data/products.json`,
                type: 'GET'
            });
            const owl = $('.product_slider1_section2');
            updateSlider(data, owl);
        } catch (error) {
            console.log('error in section 2: ', error)
        }
    }
    const renderShopSection3 = async () => {
        try {
            // fake api
            const data  = await $.ajax({
                url: `${host}danny_data/products.json`,
                type: 'GET'
            });
            // slider 1
            const owl1 = $('.product_slider1_section3');
            updateSlider(data, owl1, true);
    
            // slider 2
            const owl2 = $('.product_slider2_section3');
            updateSlider(data, owl2, true);
    
            // slider 3
            const owl3 = $('.product_slider3_section3');
            updateSlider(data, owl3, true);
        } catch (error) {
            console.log('error in section 3: ', error)
        }
    }

    const updateSlider = (data, owlElement, isSection3) => {
        owlElement.html('');

        let section3Slide = $("<div>", {"class": "item"});

        data.forEach((product, i) => {
            if(product.price)
            product.price = +product.price;
            if(product.old_price)
            product.old_price = +product.old_price;
            
            product.in_cart = state.cartIds.includes(product.id);
            product.in_wishlist = state.wishlistIds.includes(product.id);

            if(isSection3) {
                if(i % 3 === 0) {
                    section3Slide = $("<div>", {"class": "item"});
                    section3Slide.append(productBoxSmallHTML(product));
                    owlElement.append(section3Slide);
                } else {
                    section3Slide.append(productBoxSmallHTML(product));
                }
            } else {
                owlElement.append(productBoxHTML(product));
            }
        });
        refreshOwlCarousel(owlElement);
    }
    await renderShopSection1();
    await renderShopSection2();
    await renderShopSection3();
    // re-assign popups
    magnificPopups();
}