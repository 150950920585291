const queryString = require('query-string');

export const filtersToUrl = (selectedFilters) => {
    const uniqueFilters = selectedFilters.reduce((acc, item) => {
        if(acc[item.type] !== undefined) {
            acc[item.type] = [...acc[item.type], item.value];
        } else {
            acc[item.type] = [];
            acc[item.type] = [...acc[item.type], item.value];
        }
        return acc;
    }, {});
    
    const url = new URL(location.origin + location.pathname);
    for(let key in uniqueFilters) {
        if(Object.keys(uniqueFilters).length > 0) {
            url.searchParams.set(key, uniqueFilters[key].toString());
        }
    }

    history.pushState(null, "", url);
}

export const urlToFilters = (filters) => {
    const parsed = queryString.parse(location.search);
    const splittedValues = {};
    for(let key in parsed) {
        splittedValues[key] = parsed[key].split(',');
    }
    const checkedIds = [];
    const checkedFilters = [];
    for(let key in splittedValues) {
        splittedValues[key].forEach(value => {
            const filterItem = filters.filter(item => item.type === key)[0];
            const filterId = filterItem.id;
            const itemId = filterItem.items.filter(item => item.value === value)[0].id;
            const checkedId = `filter-${filterId}-${itemId}`;
            const checkedFilter = {checkedValue: `${filterId}-${itemId}`, type: key, value}
            checkedIds.push(checkedId);
            checkedFilters.push(checkedFilter);
        })
    }
    return {checkedIds, checkedFilters};
}