// require("./bootstrap");
window.Popper = require("popper.js").default;
window.$ = window.jQuery = require("jquery");

// require('bootstrap');
require("bootstrap/js/src/util");
require("bootstrap/js/src/dropdown");
require("bootstrap/js/src/carousel");
require("bootstrap/js/src/collapse");
// require( 'bootstrap/js/src/modal');
require("bootstrap/js/src/tab");

require("../vendors/owlcarousel/js/owl.carousel.min.js");
require("../vendors/magnific-popup/magnific-popup.min.js");

import { pagePreLoad } from "./theme-functions/loading";
import {
  openMobSearch,
  hideNavOnPageClick,
  hideNavOnLinkClick,
} from "./theme-functions/navbar";
import { carousel_slider } from "./theme-functions/sliders";
import { magnificPopups } from "./theme-functions/popups";
import { toggleGridList } from "./theme-functions/product-list-grid";
import {
  productListFilter,
  showMoreFilters,
} from "./theme-functions/product-list-filter";
import { starRating } from "./theme-functions/star-rating";
import {shop} from './shop';


$(window).on("load", function () {
  pagePreLoad();
});

openMobSearch();
hideNavOnPageClick();
hideNavOnLinkClick();
carousel_slider();
magnificPopups();
toggleGridList();
productListFilter();
showMoreFilters();
starRating();
shop();



/*data image src*/
$(".background_bg").each(function () {
  var attr = $(this).attr("data-img-src");
  if (typeof attr !== typeof undefined && attr !== false) {
    $(this).css("background-image", "url(" + attr + ")");
  }
});

$('[name="address_option"]').on("change", function () {
  var $value = $(this).attr("value");
  $(".address-text").slideUp();
  $('[data-method="' + $value + '"]').slideDown();
});
$(document).on("ready", function () {
  console.log("1111111111111");
});


