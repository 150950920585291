export function refreshOwlCarousel(owlElement) {
    owlElement.trigger('destroy.owl.carousel');
    owlElement.owlCarousel({
        dots: owlElement.data("dots"),
        loop: owlElement.data("loop"),
        items: owlElement.data("items"),
        margin: owlElement.data("margin"),
        mouseDrag: owlElement.data("mouse-drag"),
        touchDrag: owlElement.data("touch-drag"),
        autoHeight: owlElement.data("autoheight"),
        center: owlElement.data("center"),
        nav: owlElement.data("nav"),
        rewind: owlElement.data("rewind"),
        navText: [
            // '<i class="ion-ios-arrow-left"></i>',
            // '<i class="ion-ios-arrow-right"></i>',
            '<i class="fas fa-chevron-left"></i>',
            '<i class="fas fa-chevron-right"></i>'
        ],
        autoplay: owlElement.data("autoplay"),
        animateIn: owlElement.data("animate-in"),
        animateOut: owlElement.data("animate-out"),
        autoplayTimeout: owlElement.data("autoplay-timeout"),
        smartSpeed: owlElement.data("smart-speed"),
        responsive: owlElement.data("responsive"),
    });
}
export const getCartIds = () => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    const ids = cart.map(item => item.id);
    return ids;
}

export const updateCartIcon = (id) => {
    const rootElement = $(`[data-id=${id}]`);
    const iconElement = rootElement.find('.add_to_cart');
    
    if($(iconElement).hasClass('isInCart')) {
        $(iconElement).removeClass('isInCart');
        $(iconElement).html('<i class="icon-basket-loaded"></i> Dodaj U Korpu')
    } else {
        $(iconElement).addClass('isInCart');
        $(iconElement).html('<i class="icon-basket-loaded"></i> U Korpi')
    }
}
export const updateWishlistIcon = (id) => {
    const rootElement = $(`[data-id=${id}]`);
    const iconElement = rootElement.find('.toggle_wishlist');
    $(iconElement).toggleClass('isInWishlist');
}

export const setFixedHeader = () => {
    //on page load
    if(window.scrollY > 150) {
        $('.middle-header').addClass('fixed');
        $('body').css('margin-top', '100px')
    }
    //on scroll
    $(document).on('scroll', () => {
        if(window.scrollY > 150) {
            $('.middle-header').addClass('fixed');
            $('body').css('margin-top', '100px')
        } else {
            $('.middle-header').removeClass('fixed');
            $('body').css('margin-top', 'unset');
        }
    })
}

export const handleStorageChange = e => {
    if (e.originalEvent.key === 'cart' && e.originalEvent.oldValue !== e.originalEvent.newValue) {
        window.location.reload();
    }
    if (e.originalEvent.key === 'wishlist' && !e.originalEvent.oldValue !== e.originalEvent.newValue) {
        window.location.reload();
    }
}
export const disableNegativeValue = (event) => {
    console.log(event.target.value)
    if(event.target.value === 0)
    event.target.value = 1;

    if(event.key === '-' || (event.key === '0' && event.target.value < 1)) {
        return false;
    }
}