// import $ from 'jquery';
// window.jQuery = $;
// window.$ = $;

import { renderCartItems} from './shop-modules/cart/cart.js';
import { home } from './shop-modules/home/home.js';
import { list } from './shop-modules/list/list.js';
import { quickview } from './shop-modules/quickview/quickview.js';
import { setFixedHeader, handleStorageChange } from './shop-modules/utility.js';
import { wishlist } from './shop-modules/wishlist/wishlist.js';


const isHomePage = $('.front-page').length > 0;
const isProductListPage = $('.product-list-page').length > 0;

export const shop = () => {
    // refresh all opened tabs on cart or wishlist change
    $(window).bind('storage', handleStorageChange);
    
    // sticky navigation on scroll
    setFixedHeader()
    
    
    renderCartItems();
    wishlist();
    
    if(isHomePage) {
        home();
    }
    
    if(isProductListPage) {
        list();
    }
    
    quickview();
}