import { host } from "../config.js";

export const emptyWishlistHTML = `<h3 style='padding: 20px; text-align: center;'>Nemate omiljenih proizvoda</h3>`;

export const wishlistItemRowHTML = item => `
    <tr
        class="wishlist_item product_data" 
        data-id="${item.id}"
    >
        <td class="product-thumbnail">
            <a href="${host}product-single.php">
                <img src="${host}/img/products/${item.image}" alt="">
            </a>
        </td>
        <td class="product-name" data-title="Proizvod">
            <a href="${host}product-single.php">
                ${item.name}
            </a>
        </td>
        <td class="product-price" data-title="Cena">
            ${item.price.toFixed(2)}
        </td>
        <td class="product-stock-status" data-title="Dostupno">
            <span class="badge badge-pill badge-success">Na lageru</span>
        </td>
        <td class="product-add-to-cart">
            <a 
                href="javascript:void(0)" 
                class="btn btn-fill-out add_to_cart ${item.in_cart ? 'isInCart' : ''}"
            >
                <i class="icon-basket-loaded"></i> ${item.in_cart ? 'U Korpi' : ' Dodaj U Korpu'}
            </a>
        </td>
        <td class="product-remove" data-title="Izbaci">
            <a 
                href="javascript:void(0)" 
                class="item_remove remove_from_wishlist"
                data-id="${item.id}"
            >
                <i class="ti-close"></i>
            </a>
        </td>
    </tr>
`;