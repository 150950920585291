// import { magnificPopups } from "../../theme-functions/popups.js";
import { productListFilter, showMoreFilters } from "../../theme-functions/product-list-filter.js";
import { host } from "../config.js";
import { filtersToUrl, urlToFilters } from "./filterHelpers.js";
import { filterHTML, productBoxInListHTML } from "./htmlMarkups.js";

const state = {
    data: [],
    products: [],
    wishlistIds: [],
    cartIds: [],
    filters: [],
    selectedFilters: [],
}

export async function list() {
    await getData();
    await getFilters();
    parseCheckedFiltersFromUrl()
    applyFilters();
    renderList();
}
// fake api
const getData = async () => {
    state.data  = await $.ajax({
        url: `${host}danny_data/products.json`,
        type: 'GET'
    });
}
const getFilters = async () => {
    if(state.filters.length < 1) {
        state.filters  = await $.ajax({
            url: `${host}danny_data/my_filters.json`,
            type: 'GET'
        });
        $('#sidebar_filter_form').html(state.filters.map(filter => filterHTML(filter)));
        productListFilter();
        showMoreFilters();
    }
}
const parseCheckedFiltersFromUrl = () => {
    const {checkedIds, checkedFilters} = urlToFilters(state.filters);
    state.selectedFilters = checkedFilters;
    console.log({checkedIds, checkedFilters})
    const filterInputs = document.querySelectorAll(".js__filter_input");
    if(checkedIds.length > 0) {
        filterInputs.forEach(input => {
            $(input).prop('checked', false);
            checkedIds.forEach(id => {
                if(input.id === id) {
                    $(input).prop('checked', true);
                }
            })
        })
    } else {
        filterInputs.forEach(input => {
            $(input).prop('checked', false);
        })
    }
}
const applyFilters = () => {
    if(state.selectedFilters.length > 0) {
        let filteredData = []
        state.selectedFilters.forEach(selectedFilter => {
            const filtered = state.data.filter(item => (
                selectedFilter.value === item[selectedFilter.type] &&
                !filteredData.find(item2 => item2.id === item.id)
            ))
            filteredData = [...filteredData, ...filtered];
        })
        state.data = [...filteredData];
    } 
    console.log(state.data)
}
const renderList = () => {
    state.wishlistIds = JSON.parse(localStorage.getItem('wishlist')) || [];
    const cart = JSON.parse(localStorage.getItem('cart')) || []
    state.cartIds = cart.map(cartItem => cartItem.id);
    

    state.products = state.data.map(product => {
        if(product.price)
        product.price = +product.price;
        if(product.old_price)
        product.old_price = +product.old_price;
        if(product.power)
        product.power = +product.power;

        product.in_cart = state.cartIds.includes(product.id);
        product.in_wishlist = state.wishlistIds.includes(product.id);

        return productBoxInListHTML(product);
    });

    $('.shop_container').html(state.products);
}

export const filterChecked = (checkedValue) => {
    const [filterId, itemId] = checkedValue.split('-');
    const checkedFilter = state.filters.find(filter => filter.id === +filterId);
    const checkedItem = checkedFilter.items.find(item => item.id === +itemId);
    const type = checkedFilter.type;
    const value = checkedItem.value;
    state.selectedFilters.push({type, value, checkedValue});
    filtersToUrl(state.selectedFilters);
    list();
}

export const filterUnchecked = (value) => {
    state.selectedFilters = state.selectedFilters.filter(selectedFilter => selectedFilter.checkedValue !== value);
    filtersToUrl(state.selectedFilters);
    list();
}

window.addEventListener('popstate', list);