function starRating(){
    // star_rating
    $('.star_rating span').on('click', function(){
        var onStar = parseFloat($(this).data('value'), 10); // The star currently selected
        var stars = $(this).parent().children('.star_rating span');
        for (var i = 0; i < stars.length; i++) {
            $(stars[i]).removeClass('selected');
        }
        for (i = 0; i < onStar; i++) {
            $(stars[i]).addClass('selected');
        }

        $('#star_rating_value').val(onStar)
    });
}
export {starRating}