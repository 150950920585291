function magnificPopups() {
  $(".content-popup").magnificPopup({
    type: "inline",
    preloader: true,
    mainClass: "mfp-zoom-in",
  });

  $(".image_gallery").each(function () {
    // the containers for all your galleries
    $(this).magnificPopup({
      delegate: "a", // the selector for gallery item
      type: "image",
      gallery: {
        enabled: true,
      },
    });
  });

  $(".popup-ajax").magnificPopup({
    type: "ajax",
  });

  $(".video_popup, .iframe_popup").magnificPopup({
    type: "iframe",
    removalDelay: 160,
    mainClass: "mfp-zoom-in",
    preloader: false,
    fixedContentPos: false,
  });

  $('.image-popup-no-margins').magnificPopup({
		type: 'image',
		closeOnContentClick: true,
		closeBtnInside: false,
		fixedContentPos: true,
		mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
		image: {
			verticalFit: true
		},
		zoom: {
			enabled: true,
			duration: 300 // don't foget to change the duration also in CSS
		}
	});

  // ----------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------
  // Zoom image when click on icon
  // Set up gallery on click
	// var galleryZoom = $('#pr_item_gallery');
	// galleryZoom.magnificPopup({
	// 	delegate: 'a',
	// 	type: 'image',
	// 	gallery:{
	// 		enabled: true
	// 	},
	// 	callbacks: {
	// 		elementParse: function(item) {
	// 			item.src = item.el.attr('data-zoom-image');
	// 		}
	// 	}
	// });
	// $('.product_img_zoom').on('click', function(){
	// 	var atual = $('#pr_item_gallery a').attr('data-zoom-image');
	// 	$('body').addClass('zoom_gallery_image');
	// 	$('#pr_item_gallery .item').each(function(){
	// 		if( atual == $(this).find('.product_gallery_item').attr('data-zoom-image') ) {
	// 			return galleryZoom.magnificPopup('open', $(this).index());
	// 		}
	// 	});
	// });
}
export { magnificPopups };
