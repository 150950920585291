import { updateCartOnBlur } from "../cart/cart.js";
import { host } from "../config.js";
import { showPopup } from "../ui/popup.js";
import { myLoader } from "../ui/ui.js";
// import { loader } from "../ui/ui.js";
import { disableNegativeValue } from "../utility.js";
import {quickviewHTML} from './htmlMarkups.js';

const state = {
    wishlistIds: [],
    cartIds: [],
    cart:[]
}

export function quickview() {
    const quickviewEvents = (e) => {
        const dataElement = e.target.closest('.product_data');
        if(dataElement) {
            if(e.target.closest('.custom-popup')) {
                const id = +dataElement.dataset.id;
                $('.loader_container').fadeIn(150);
                // setTimeout(() => {
                    
                    getProduct(id)
                // }, 500);
            }
        }
    }
    
    const getProduct = async (id) => {
        const data  = await $.ajax({
            url: `${host}danny_data/products.json`,
            type: 'GET'
        });

        state.wishlistIds = JSON.parse(localStorage.getItem('wishlist')) || [];
        state.cart = JSON.parse(localStorage.getItem('cart'));
        state.cartIds = state.cart.map(cartItem => cartItem.id) || [];
        
        const product = data.find(item => item.id === id);
        product.in_wishlist = state.wishlistIds.includes(product.id);
        product.in_cart = state.cartIds.includes(product.id);
        if(product.in_cart)
        product.qty = state.cart.filter(cartItem => cartItem.id === id)[0].qty;
        product.price = +product.price;
        product.old_price = +product.old_price;
        product.discount_persent = +product.discount_persent;
        
        $('.loader_container').fadeOut(150);
        showPopup(quickviewHTML(product));
        
        const popupElement = $('.popup-content');
        const inputElement = $(popupElement).find('.qty');
        inputElement.on('keydown', disableNegativeValue);
        inputElement.on('keydown', updateCartOnBlur);
    }
    $(document).on('click', quickviewEvents);
}