import { host } from "../config.js";

// product box
export const productBoxHTML = product => `
    <div class="item">
        <div 
            class="product_wrap product_data" 
            data-id="${product.id}"
        >
            ${product.is_new ? '<span class="pr_flash">Novo</span>' : ''}
            <div class="product_img">
                <a href="${host}product-single.php">
                    <img src="${host}/img/products/${product.image}" alt="">
                </a>
                <div class="product_action_box">
                    <ul class="list_none pr_action_btn">
                        <li class="add-to-cart">
                            <a 
                                href="javascript:void(0)" 
                                class="add_to_cart ${product.in_cart ? 'isInCart' : ''}"
                            >
                                <i class="icon-basket-loaded"></i> ${product.in_cart ? 'U Korpi' : ' Dodaj U Korpu'}
                            </a>
                        </li>

                        <li>
                            <a href="javascript:void(0)"  class="custom-popup">
                                <i class="icon-magnifier-add"></i>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" class="toggle_wishlist ${product.in_wishlist ? 'isInWishlist' : ''}">
                                <i class="icon-heart" ></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="product_info box-product_info">
                <h6 class="product_title">
                    <a href="${host}product-single.php">
                        ${product.name}
                    </a>
                </h6>
                <div class="product_price">
                    ${product.old_price ? 
                        `
                            <span class="price">${product.price.toFixed(2)}</span>
                            <del>${product.old_price.toFixed(2)}</del>
                            <div class="on_sale">
                                <span>${product.discount_persent}% popusta</span>
                            </div>
                        ` : 
                        `
                            <span class="price">${product.price.toFixed(2)}</span>
                        `}
                </div>
                <div class="rating_wrap">
                    <div class="rating">
                        <div class="product_rate" style="width:80%"></div>
                    </div>
                    <span class="rating_num">(21)</span>
                </div>


                <div class="pr_desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus blandit massa enim. Nullam id varius nunc id varius nunc.</p>
                </div>
            </div>
        </div>
    </div>
`;

export const productBoxSmallHTML = product => `
    <div class="product_wrap">
        ${product.is_new ? '<span class="pr_flash">Novo</span>' : ''}
        <div class="product_img">
            <a href="${host}product-single.php">
                <img src="${host}/img/products/${product.image}" alt="">
            </a>
        </div>
        <div class="product_info">
            <h6 class="product_title">
                <a href="${host}product-single.php">
                    ${product.name}
                </a>
            </h6>
            <div class="product_price">
                ${product.old_price ? 
                    `
                        <span class="price">${product.price.toFixed(2)}</span>
                        <del>${product.old_price.toFixed(2)}</del>
                        <div class="on_sale">
                            <span>${product.discount_persent}% popusta</span>
                        </div>
                    ` : 
                    `
                        <span class="price">${product.price.toFixed(2)}</span>
                    `}
            </div>
            <div class="rating_wrap">
                            <div class="rating">
                                <div class="product_rate" style="width:80%"></div>
                            </div>
                            <span class="rating_num">(21)</span>
                        </div>

            
            <div class="pr_desc">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus blandit massa enim. Nullam id varius nunc id varius nunc.</p>
            </div>
        </div>
    </div>
`;