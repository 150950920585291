import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import ee from 'event-emitter';

const emitter = new ee();

export const notify = (msg, type, cb) => {
    emitter.emit('notification', msg, type, cb);
}
const notificationElement = $('.custom_notification');

const state = {
    show: false,
    timer: null,
    type: '',
    cb: null,
    msg:''
}
const icons = {
    success:'<i class="fas fa-check" style="color: #2efd50b4;"></i>',
    danger:'<i class="fas fa-exclamation" style="color: #fd2e2e;"></i>',
    info:'<i class="fas fa-info" style="color: #2ea0fd;"></i>'
}

emitter.on('notification', (msg, type, cb) => {
    state.type = type;
    state.cb = cb;
    state.msg = msg;
    onShow();
});
$(notificationElement).on('click', () => {
    console.log('clicked')
    if(state.cb)
    state.cb();
    state.show = false;
    notificationElement.removeClass('show_notification');
});

const onShow = () => {
    if(state.timer) {
        clearTimeout(state.timer);
        state.show = false;
        notificationElement.removeClass('show_notification');

        state.timer = setTimeout(() => {
            showNotification();
        }, 100);
    } else {
        showNotification();
    }
}
const showNotification = () => {
    state.show = true;
    notificationElement.html(icons[state.type] + state.msg)
    notificationElement.addClass('show_notification');

    state.timer = setTimeout(() => {
        state.show = false;
        notificationElement.removeClass('show_notification');
    }, 4000);
}

