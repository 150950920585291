import {
    cartItemHTML,
    emptyCartHTML,
    amountHTML,
    cartTotalHTML,
    cartItemRowHTML,
    cartTotalContainerHTML,
} from './htmlMarkups.js';
import {host} from '../config.js';
import { disableNegativeValue, updateCartIcon } from '../utility.js';
import { notify } from '../ui/notification.js';

import $ from 'jquery';
window.jQuery = $;
window.$ = $;


const isCartPage = $('.cart-page').length > 0;
const state = {
    cart: [],
    products: [],
    total: 0,
    cartIds: [],
}

export const renderCartItems = async () => {
    state.cart = JSON.parse(localStorage.getItem('cart')) || [];
    state.cartIds = state.cart.map(cartItem => cartItem.id);
    
    // fake api
    try {
        const data  = await $.ajax({
            url: `${host}danny_data/products.json`,
            type: 'GET'
        });
        state.products = state.cart.map(cartItem => {
            const product = data.find(item => item.id === cartItem.id);
            product.price = +product.price;
            product.qty = cartItem.qty;
            return product;
        });
        state.total = state.products
        .reduce((acc, item) => {
            acc += item.price * item.qty;
            return acc;
        }, 0);

        // cart popup
        renderCartPopupList();

        //if cart page
        if(isCartPage)
        renderCartItemsTable();

        removeEvents();
        setEvents();
    } catch (error) {
        console.log(error);
    }
}

const renderCartPopupList = () => {
    let cartItems;
    if(state.cart.length > 0) {
        cartItems = state.products.map(product => cartItemHTML(product)).join('');
    } else {
        cartItems = emptyCartHTML;
    }
    $('.cart_list').html(cartItems);

    updateCartCountHeader();
    updateTotalPriceHeader();
};
// number of different products in the cart
const updateCartCountHeader = () => {
    const cartCount = state.cart.length;
    $('.cart_count').html(cartCount);
}
const updateTotalPriceHeader = () => {
    $('.amount').html(amountHTML(state.total));
    $('.cart_total').html(cartTotalHTML(state.total));
}

const renderCartItemsTable = () => {
    const cartItemsHeadElement = $('.cart_items_head');
    let cartItems;
    if(state.cart.length > 0) {
        cartItems = state.products.map(product => cartItemRowHTML(product)).join('');
        $(cartItemsHeadElement).show()
    } else {
        cartItems = emptyCartHTML;
        $(cartItemsHeadElement).hide()
    }
    $('.cart_items_table').html(cartItems);

    renderCartPageTotal();
};
const renderCartPageTotal = () => {
    $('.cart_total_container').html(cartTotalContainerHTML(state.total));
}

const addToCart = (newCartItem) => {
    if(!state.cart.some(cartItem => cartItem.id === newCartItem.id)) {
        state.cart.push(newCartItem);
        updateCartIcon(newCartItem.id);
    } else {
        state.cart = state.cart.map(cartItem => cartItem.id === newCartItem.id ? newCartItem : cartItem);
    }
    localStorage.setItem('cart', JSON.stringify(state.cart));

    renderCartItems();
}
const removeFromCart = (id) => {
    state.cart = state.cart.filter(cartItem => cartItem.id !== id);
    localStorage.setItem('cart', JSON.stringify(state.cart));

    renderCartItems();
    updateCartIcon(id);
}

const removeEvents = () => {
    $(document).off('click',cartClickEvents);
    $('.qty').off('keydown', disableNegativeValue);
    $('.qty').off('blur', updateCartOnBlur);
}
const setEvents = () => {
    $(document).on('click',cartClickEvents);

    $('.qty')
    .attr('min', '1')
    .on('keydown', disableNegativeValue)
    
    $('.qty')
    .attr('min', '1')
    .on('blur', updateCartOnBlur)        
}

const cartClickEvents = event => {
    const isCartPage = $('.cart-page').length > 0;
    // add to cart
    const addToCartElement = event.target.closest('.add_to_cart');
    if(addToCartElement) {
        const productDataElement =  event.target.closest('.product_data');
        const newCartItem = {};
        newCartItem.id = +productDataElement.dataset.id;
        newCartItem.qty = +$(productDataElement).find('.qty').val() || 1;
        
        addToCart(newCartItem);
        notify('Dodato u korpu!', 'success')
    }

    //remove from cart
    const removeFromCartElement = event.target.closest('.remove_from_cart');
    if(removeFromCartElement) {
        // animate removal
        const cartItemElement = event.target.closest('.product_data');
        cartItemElement.style.transition = 'transform 150ms ease-in-out';
        cartItemElement.style.transform = 'scale(0)';

        const id = +removeFromCartElement.dataset.id;
        setTimeout(() => {
            removeFromCart(id);
        }, 200);
        notify('Uklonjeno iz korpe!', 'success')
    }

    // increment quantity
    const incQty = event.target.closest('.plus');
    const itemRoot = event.target.closest('.product_data');
    if(incQty) {
        const inputElement = $(itemRoot).find('.qty');
        const inputValue = +inputElement.val();
        inputElement.val(inputValue + 1);

        const productDataElement =  event.target.closest('.product_data');
        const newCartItem = {};
        newCartItem.id = +productDataElement.dataset.id;
        newCartItem.qty = +inputElement.val() || 1;
        const inCart = state.cartIds.includes(newCartItem.id);
        if(isCartPage || inCart) {
            addToCart(newCartItem);
            notify('Dodato u korpu!', 'success')
        }
    }
    // decrement quantity
    const decQty = event.target.closest('.minus');
    if(decQty) {
        const inputElement = $(itemRoot).find('.qty');
        const inputValue = +inputElement.val();
        if(inputValue > 1)
        inputElement.val(inputValue - 1);

        const productDataElement =  event.target.closest('.product_data');
        const newCartItem = {};
        newCartItem.id = +productDataElement.dataset.id;
        newCartItem.qty = +inputElement.val() || 1;
        const inCart = state.cartIds.includes(newCartItem.id);
        if(isCartPage || inCart) {
            addToCart(newCartItem);
            if(inputValue > 1)
            notify('Uklonjeno iz korpe!', 'success')
        }
    }
}
export function updateCartOnBlur(event) {
    const inputElement = $(this);
    const productDataElement =  event.target.closest('.product_data');
    const newCartItem = {};
    newCartItem.id = +productDataElement.dataset.id;
    newCartItem.qty = +inputElement.val() || 1;
    
    const inCart = state.cartIds.includes(newCartItem.id);
    if(isCartPage || inCart) {
        addToCart(newCartItem);
    }
}

