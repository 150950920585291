import { filterChecked, filterUnchecked } from "../shop-modules/list/list.js";

function productListFilter() {
  const form = document.getElementById("sidebar_filter_form");
  if (!form) {
    return;
  }
  const filterInputs = document.querySelectorAll(".js__filter_input");

  filterInputs.forEach((item) => {
    item.addEventListener("change", (event) => {
      const checkboxValue = event.target.value;
      if (event.target.checked) {
        console.log("checkboxValue :>> ", checkboxValue);
        filterChecked(checkboxValue);
        // form.submit()
      } else {
        console.log("checkboxValue :>> ", checkboxValue);
        filterUnchecked(checkboxValue)
      }
    });
  });
}

function showMoreFilters() {
  $(".filter-list__btn").on("click", function (e) {
    e.preventDefault();

    const widget = $(this).closest(".filter-widget");
    const listEl = $(this).closest(".filter-widget").children(".list_brand");
    const fullListHeight = listEl.children().length * 35; // height of li is 35 px

    if (widget.hasClass("open")) {
      widget.removeClass("open");
      this.innerText = "Prikaži više";
      listEl.height(170);
    } else {
      widget.addClass("open");
      listEl.height(fullListHeight);
      this.innerText = "Prikaži manje";
    }
  });
}

export { productListFilter, showMoreFilters };
