import {host} from '../config.js';
import { notify } from '../ui/notification.js';
import { updateWishlistIcon } from '../utility.js';
import { emptyWishlistHTML, wishlistItemRowHTML } from './htmlMarkups.js';
// const isWishlistPage = $('.wishlist-page').length > 0;
const state = {
    wishlistIds: [],
    products: [],
    cartIds: [],
}

export function wishlist() {
    const renderWishlistItems = async () => {
        state.wishlistIds = JSON.parse(localStorage.getItem('wishlist')) || [];
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        state.cartIds = cart.map(cartItem => cartItem.id);
        try {
            // fake api
            const data  = await $.ajax({
                url: `${host}danny_data/products.json`,
                type: 'GET'
            });
            state.products = state.wishlistIds.map(wishlistId => {
                const product = data.find(item => item.id === wishlistId);
                product.price = +product.price;
                product.in_cart = state.cartIds.includes(product.id);
                return product;
            })
            renderWishlistTable();

            removeEvents();
            setEvents();
        } catch (error) {
            console.log('error in wishlist: ', error);
        }
    }
        
    const renderWishlistTable = () => {
        const wishlistHeadElement = $('.wishlist_items_head');
        let wishlistItems;
        if(state.wishlistIds.length > 0) {
            wishlistItems = state.products.map(product => wishlistItemRowHTML(product)).join('');
            $(wishlistHeadElement).show()
        } else {
            wishlistItems = emptyWishlistHTML;
            $(wishlistHeadElement).hide()
        }
        $('.wishlist_items_table').html(wishlistItems);
        updateWishlistCountHeader();
    };
    // number of items in wishlist
    const updateWishlistCountHeader = () => {
        const wishlistCount = state.wishlistIds.length;
        $('.wishlist_count').html(wishlistCount);
    }
        
    const updateWishlist = (id) => {
        if(!state.wishlistIds.some(wishlistId => wishlistId === id)) {
            state.wishlistIds.push(id);
            notify('Dodato u omiljene!', 'success');
        } else {
            state.wishlistIds = state.wishlistIds.filter(wishlistId => wishlistId !== id);
            notify('Uklonjeno iz omiljenih!', 'success');
        }
        localStorage.setItem('wishlist', JSON.stringify(state.wishlistIds));
        updateWishlistIcon(id);

        renderWishlistItems();
    }
    const removeFromWishlist = (id) => {
        state.wishlistIds = state.wishlistIds.filter(wishlistId => wishlistId !== id);
        localStorage.setItem('wishlist', JSON.stringify(state.wishlistIds));
    
        renderWishlistItems();
        updateWishlistIcon(id);
        notify('Uklonjeno iz omiljenih!', 'success');
    }

    const removeEvents = () => {
        $(document).off('click',wishlistClickEvents);
    }
    const setEvents = () => {
        $(document).on('click', wishlistClickEvents); 
    }
    // events
    const wishlistClickEvents = event => {
        // toggle wishlist
        const toggleWishlistElement = event.target.closest('.toggle_wishlist');
        if(toggleWishlistElement) {
            const productDataElement =  event.target.closest('.product_data');
            const id = +productDataElement.dataset.id;
            
            updateWishlist(id);
        }
    
        //remove from wishlist
        const removeFromWishlistElement = event.target.closest('.remove_from_wishlist');
        if(removeFromWishlistElement) {
            // animate removal
            const wishlistItemElement = event.target.closest('.wishlist_item');
            wishlistItemElement.style.transition = 'transform 150ms ease-in-out';
            wishlistItemElement.style.transform = 'scale(0)';

            const id = +removeFromWishlistElement.dataset.id;
            setTimeout(() => {
                removeFromWishlist(id);
            }, 200);
        }

    }

    renderWishlistItems();
}